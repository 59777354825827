import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    listBank: [],
    listOptionBank: [],
  },
  getters: {},
  mutations: {
    SET_LIST_BANK(state, item) {
        const newData = item.map(x => ({ value: x._id, text: x.name }))
        state.listOptionBank = newData
        state.listBank = item
    } 
  },
  actions: {
    getBank({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/bank/getbanks')
          .then(response => {
            commit('SET_LIST_BANK', response.data.items)
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
