import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import user from './user'
import customer from './customer'
import transaction from './transaction'
import game from './game'
import bank from './bank'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    customer,
    user,
    transaction,
    game,
    bank,
  },
  strict: process.env.DEV,
})
