import axios from '../../http/axios'
// const baseUrl = "http://localhost:3001"

export default {
  namespaced: true,
  state: {
    credit: 0,
  },
  getters: {},
  mutations: {
    SET_CREDIT(state, item) {
      state.credit = item
    }
  },
  actions: {
    getDeposit({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/transaction/getdeposit', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getWithdraw({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/transaction/getwithdraw', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    Withdraw({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/transaction/withdraw', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCredit({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/transaction/credit', item)
          .then(response => {
            commit('SET_CREDIT', response.data.item.credit)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
