import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
// import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import production from './routes/production'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'homepage' } },
    ...production,
    // ...apps,
    // ...dashboard,
    // ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    {
      path: '*',
      redirect: '/',
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   const isLoggedIn = isUserLoggedIn()
//   if (!canNavigate(to)) {
//   // // Redirect to login if not logged in
//     if (!isLoggedIn) {
//       store.commit('customer/SET_OPEN_REGISTER')
//       if (to.fullPath !== '/') return next({ path: '/' })
//     }
//   }

//   // Redirect if logged in
//   // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   //   const userData = getUserData()
//   //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   // }
//   return next()
// })

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('userData')
  if (to.meta.requiredAuth) {
    if (!loggedIn) {
      next({ name: 'homepage' })
    }
    next()
  }

  // if (to.meta.requiredAuth && loggedIn) {
  //   next({ name: 'homepage' })
  // }
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
