export default [
  {
    path: '/',
    name: 'homepage',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/production/homepage/homepage.vue'),
  },
  {
    path: '/games/show',
    name: 'show-game',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/production/game/show-game.vue'),
  }, 
  {
    path: '/profile',
    name: 'profile-edit',
    meta: {
      layout: 'profile',
      requiredAuth: true,
    },
    component: () => import('@/views/production/profile/edit-profile.vue'),
  },
  {
    path: '/changepass',
    name: 'profile-changepass',
    meta: {
      layout: 'profile',
      requiredAuth: true,
    },
    component: () => import('@/views/production/profile/change-pass.vue'),
  },
  {
    path: '/deposit',
    name: 'wallet-deposit',
    meta: {
      layout: 'profile',
      requiredAuth: true,
    },
    component: () => import('@/views/production/wallet/deposit-wallet.vue'),
  },
  {
    path: '/withdraw',
    name: 'wallet-withdraw',
    meta: {
      layout: 'profile',
      requiredAuth: true,
    },
    component: () => import('@/views/production/wallet/withdraw-wallet.vue'),
  },
 {
    path: '/history/:type',
    name: 'wallet-history',
    meta: {
      layout: 'profile',
      requiredAuth: true,
    },
    component: () => import('@/views/production/wallet/history-wallet.vue'),
  },
  {
    path: '/games-enter',
    name: 'login-game',
    meta: {
      layout: 'full-clean',
    },
    component: () => import('@/views/production/game/PlayGame.vue')
  }, 
  

]
