import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    token: '',
    auth: false,
    modalCredit: false,
    indexModal: 0,
    modalWithdraw: false,
    credit: 0,
    spinnerCredit: false,
  },
  mutations: {
    SET_BEARER(state, Token) {
      axios.defaults.headers.common.Authorization = `Bearer ${Token}`
    },
    SET_LOGIN_COMPLETE(state, item) {
      state.token = item.token
      state.auth = item.auth
    },
    SET_LOGOUT(state, item) {
      state.token = ''
      state.auth = false
    },
    SET_OPEN_MODAL_CREDIT(state, item) {
      if (item.type === 'withdraw') {
        state.indexModal = 1
      } else {
        state.indexModal = 0
      }
      state.modalCredit = true
    },
    SET_CLOSE_MODAL_CREDIT(state, item) {
      state.modalCredit = false
    },
    SET_MODAL_WITHDRAW(state, item) {
      state.modalWithdraw = item
    },
    SET_SPINNER_CREDIT(state, item) {
      state.spinnerCredit = item
    },
    SET_CREDIT(state, item) {
      state.credit = item
    }
  },
  actions: {
    loginJWT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login', payload)
          .then(response => {
            if (response.data.success) {
              commit('SET_BEARER', response.data.accessToken)
            }
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    Register({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/account/register', payload)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    refreshToken({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/refreshtoken', payload)
          .then(response => {
            commit('SET_BEARER', response.data.accessToken)
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
