import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
// import VueAgile from 'vue-agile'

import VueNumberFormat from 'vue-number-format'
import moment from 'moment'
import momentimezone from 'moment-timezone'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import CookiesPlugin from './CookiesPlugin'

Vue.use(CookiesPlugin)

config.autoAddCss = false
library.add(fas)
library.add(far)

Vue.component('font-awesome-icon', FontAwesomeIcon)

const NumAbbr = require('number-abbreviate')

// Global Properties
Vue.prototype.$baseURL = process.env.VUE_APP_SERVER_URL

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueSweetalert2)
Vue.use(VueNumberFormat, {
  prefix: '', precision: 0, thousand: ',', acceptNegative: true,
})

Vue.filter('toTimeThai', value => {
  let dataVal = value
  return moment(dataVal).locale('th').format("LLL")
})

Vue.filter('toCurrency', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(dataVal)
})
Vue.filter('toAmount', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
  return formatter.format(dataVal)
})
Vue.filter('toCurrencyBath', value => {
  let dataVal = value
  if (typeof value !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return `${formatter.format(dataVal)} ฿`
})

Vue.filter('formatDate', value => {
  if (value) {
    return momentimezone.tz(value, 'Asia/Bangkok').locale('th').format('LLL')
  }
})

Vue.filter('toAbbreviate', value => {
  // eslint-disable-next-line no-undef
  const numAbbr = new NumAbbr(['k', 'm', 'b', 't'])
  return `${numAbbr.abbreviate(value, 2)}`
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
