// import axiosIns from '@/libs/axios'

import axios from "../../axios";

let subscribers = [];

function refreshToken() {
  return axios.post("/auth/refreshtoken", {
    refreshToken: localStorage.auth_token.split(":")[1],
  });
}

function setaccessToken(token) {
  localStorage.setItem("auth_token", token);
}

function setrefreshToken(token) {
  localStorage.setItem("refreshToken", token);
}

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

let isAlreadyFetchingAccessToken = false;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.auth_token;
    if (token) {
      let newToken = "";
      if (token !== undefined || token !== "") {
        newToken = token.split(":")[0];
      }
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${newToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => res,
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (
      (response.status === 401 || response.status === 500) &&
      !originalRequest._retry
    ) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        refreshToken().then((r) => {
          isAlreadyFetchingAccessToken = false;
          const newToken = r.data.accessToken + ":" + r.data.refreshToken;
          setaccessToken(newToken);
          // setrefreshToken(r.data.refreshToken);
          onAccessTokenFetched(r.data.accessToken);
        });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          originalRequest._retry = true;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default axios;
