import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
    getProfile({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/account/getprofile', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editProfile({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/account/edit', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    changePass({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
        .post('/account/changepass', item)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
      })
    }

  },
}
