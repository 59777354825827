import axios from "../../http/axios";
const baseURL = "http://localhost:3010";

export default {
  namespaced: true,
  state: {
    credit: 0,
    isLoading: false,
  },
  getters: {},
  mutations: {
    SET_CREDIT(state, item) {
      state.credit = item;
    },
    SET_LOADING(state, item) {
      state.isLoading = item
    }
  },
  actions: {
    getSlot({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/game/slot")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBaccarat({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/game/baccarat")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loginGame({ commit }, items) {
      return new Promise((resolve, reject) => {
        axios
          .post("/game/login", items)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    searchGame({ commit }, items ) {
      return new Promise((resolve, reject) => {
        axios
          .post("/game/search", items )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
};
