import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    listCustomer: [],
    totalItem: 0,
    typeModal: '',
    ModalRegister: false,
  },
  getters: {},
  mutations: {
    SET_LIST_CUSTOMER(state, item) {
      state.listCustomer = item
    },
    SET_OPEN_REGISTER(state) {
      state.ModalRegister = true
    },
    SET_ClOSE_REGISTER(state) {
      state.ModalRegister = false
    },
    SET_TYPE_MODAL(state, item) {
      // console.log(item)
      state.typeModal = item
    }
  },
  actions: {
    getCustomer({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('costomer/get', item)
          .then(response => {
            commit('SET_LIST_CUSTOMER', response.data.item)
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    addCustomer({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/account/register', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
